import React from "react";
import Layout from "../components/Layout/Layout";
import CharityPartners from "../components/CharityPartners";
import { graphql } from "gatsby";

import styled from "styled-components";

const FeatureImage = styled.div`
	display: flex;
	flex-flow: column nowrap;
	margin: 0 auto;
	width: 90%;
	margin-bottom: 3rem;

	img {
		margin: 0 auto;
	}

	.heroTitle {
		width: 80%;
		display: none;
		justify-content: center;
		align-content: center;
		align-items: center;
		padding: 2rem;
		text-align: center;
		background-image: linear-gradient(
			to bottom,
			rgb(105, 105, 105, 0.09),
			rgb(192, 192, 192, 1)
		);
		border-radius: 20px;
		position: absolute;
		top: 75%;
		right: 0;
		left: 0;
		margin: 0 auto;
		color: #fff249;
		h1 {
			font-size: 2rem;
			margin-bottom: 0.9rem;
			text-transform: uppercase;
			letter-spacing: 5px;
		}
	}

	@media (min-width: 1281px) {
		.heroTitle {
			top: 14.5%;
		}
	}

	@media (min-width: 768px) and (max-width: 1024px) {
		.heroTitle {
			top: 12%;

			h1 {
				font-size: 1.4rem;
				margin-bottom: 0.5rem;
				letter-spacing: 2px;
			}
		}
	}

	@media (min-width: 481px) and (max-width: 767px) {
		.heroTitle {
			top: 7%;

			h1 {
				font-size: 1.3rem;
				margin-bottom: 0.5rem;
				letter-spacing: 2px;
			}
		}
	}

	@media (min-width: 360px) and (max-width: 481px) {
		width: 100%;
		margin-bottom: 1rem;

		.heroTitle {
			top: 4.5%;
			padding: 0.5rem;

			h1 {
				font-size: 1rem;
				margin-bottom: 0.5rem;
				letter-spacing: 2px;
			}
		}
	}

	@media (max-width: 320px) {
		.heroTitle {
			top: 3%;
			padding: 0.5rem;

			h1 {
				font-size: 1rem;
				margin-bottom: 0.5rem;
				letter-spacing: 2px;
			}
		}
	}
`;

const CharityDetails = styled.div`
	display: flex;
	flex-flow: column nowrap;
	margin: 0 auto;
	margin-top: 5rem;
	margin-bottom: 5rem;
	width: 100%;
	max-width: 1130px;

	@media (max-width: 960px) {
		margin-top: 2rem;
		margin-bottom: 2rem;
	}
`;

const CharityPage = ({ data }) => {
	return (
		<Layout>
			{data.allWordpressPage.edges.map((item) => {
				return (
					<React.Fragment key={item.node.wordpress_id}>
						<FeatureImage>
							<img
								src={item.node.acf.feature_image.source_url}
								alt={item.node.acf.title}
							/>
							<div className="heroTitle">
								<h1>Charities We Have Partnered With</h1>
							</div>
						</FeatureImage>
						<CharityDetails>
							{/* Charity ONE */}
							<CharityPartners
								title={
									item.node.acf.charities_partner_section.charity_one_title
								}
								logo={
									item.node.acf.charities_partner_section.charity_one_logo
										.source_url
								}
								alt={
									item.node.acf.charities_partner_section.charity_one_logo.title
								}
								text={item.node.acf.charities_partner_section.charity_one_text}
							/>
							{/* Charity Two */}
							<CharityPartners
								title={
									item.node.acf.charities_partner_section.charity_two_title
								}
								logo={
									item.node.acf.charities_partner_section.charity_two_logo
										.source_url
								}
								alt={
									item.node.acf.charities_partner_section.charity_two_logo.title
								}
								text={item.node.acf.charities_partner_section.charity_two_text}
							/>
							{/* Charity Three */}
							<CharityPartners
								title={
									item.node.acf.charities_partner_section.charity_three_title
								}
								logo={
									item.node.acf.charities_partner_section.charity_three_logo
										.source_url
								}
								alt={
									item.node.acf.charities_partner_section.charity_three_logo
										.title
								}
								text={
									item.node.acf.charities_partner_section.charity_three_text
								}
							/>
							{/* Charity Four */}
							<CharityPartners
								title={
									item.node.acf.charities_partner_section.charity_four_title
								}
								logo={
									item.node.acf.charities_partner_section.charity_four_logo
										.source_url
								}
								alt={
									item.node.acf.charities_partner_section.charity_four_logo
										.title
								}
								text={item.node.acf.charities_partner_section.charity_four_text}
							/>
							{/* Charity Five */}
							<CharityPartners
								title={
									item.node.acf.charities_partner_section.charity_five_title
								}
								logo={
									item.node.acf.charities_partner_section.charity_five_logo
										.source_url
								}
								alt={
									item.node.acf.charities_partner_section.charity_five_logo
										.title
								}
								text={item.node.acf.charities_partner_section.charity_five_text}
							/>
							{/* Charity Six */}
							<CharityPartners
								title={
									item.node.acf.charities_partner_section.charity_six_title
								}
								logo={
									item.node.acf.charities_partner_section.charity_six_logo
										.source_url
								}
								alt={
									item.node.acf.charities_partner_section.charity_six_logo.title
								}
								text={item.node.acf.charities_partner_section.charity_six_text}
							/>
							{/* Charity Seven */}
							<CharityPartners
								title={
									item.node.acf.charities_partner_section.charity_seven_title
								}
								logo={
									item.node.acf.charities_partner_section.charity_seven_logo
										.source_url
								}
								alt={
									item.node.acf.charities_partner_section.charity_seven_logo
										.title
								}
								text={
									item.node.acf.charities_partner_section.charity_seven_text
								}
							/>
							{/* Charity Eight */}
							<CharityPartners
								title={
									item.node.acf.charities_partner_section.charity_eight_title
								}
								logo={
									item.node.acf.charities_partner_section.charity_eight_logo
										.source_url
								}
								alt={
									item.node.acf.charities_partner_section.charity_eight_logo
										.title
								}
								text={
									item.node.acf.charities_partner_section.charity_eight_text
								}
							/>
						</CharityDetails>
					</React.Fragment>
				);
			})}
		</Layout>
	);
};

export default CharityPage;

export const charityPageQuery = graphql`
	{
		allWordpressPage(filter: { slug: { eq: "charities" } }) {
			edges {
				node {
					wordpress_id
					acf {
						feature_image {
							source_url
							title
						}
						charities_partner_section {
							charity_one_title
							charity_one_logo {
								source_url
								title
							}
							charity_one_text
							charity_two_title
							charity_two_logo {
								title
								source_url
							}
							charity_two_text
							charity_three_title
							charity_three_logo {
								title
								source_url
							}
							charity_three_text
							charity_four_title
							charity_four_logo {
								title
								source_url
							}
							charity_four_text
							charity_five_title
							charity_five_logo {
								title
								source_url
							}
							charity_five_text
							charity_six_title
							charity_six_logo {
								title
								source_url
							}
							charity_six_text
							charity_seven_title
							charity_seven_logo {
								title
								source_url
							}
							charity_seven_text
							charity_eight_title
							charity_eight_logo {
								title
								source_url
							}
							charity_eight_text
						}
					}
				}
			}
		}
	}
`;
