import React from "react";

import styled from "styled-components";

const CharityWapper = styled.div`
	display: block;

	.patner__header {
		display: inline-grid;
		grid-template-columns: 3fr 6fr 3fr;
		justify-items: center;
		align-items: center;
		width: 100%;

		h3 {
			width: 100%;
			text-align: center;
			font-size: 1.3rem;
			margin-bottom: 0.9rem;
			text-transform: uppercase;
			letter-spacing: 5px;
			margin-top: 1.5rem;
			padding: 0.7rem;
			line-height: 1.5;
			font-family: "GillSans", "sans";
		}

		.special__heading-border {
			display: flex;
			flex-direction: row;
			width: 90%;
			margin: 0 auto;
			padding-top: 1rem;
			height: 1%;
			bottom: 0;
			border-bottom: 2px solid #f4cb32;
		}

		.patner__logo {
			width: 300px;

			img {
				width: 200px;
			}
		}
	}

	.patner__content {
		display: flex;
		flex-flow: row nowrap;
		justify-content: center;
		align-content: center;
		align-items: center;
		padding: 1rem;

		p {
			flex-shrink: 1;
			padding: 1.5rem;
			font-family: "GillSans", "sans";
			font-size: 1.1rem;
			line-height: 2;
		}
	}

	@media (max-width: 960px) {
		.patner__header {
			display: flex;
			flex-flow: column nowrap;

			.special__heading-border {
				width: 50%;
			}

			.patner__logo {
				img {
					margin: 0 auto;
				}
			}
		}

		.patner__content {
		}
	}
`;

const CharityPartners = (props) => {
	return (
		<>
			<CharityWapper>
				<div className="patner__header">
					<h3>{props.title}</h3>
					<div className="special__heading-border"></div>
					<div className="patner__logo">
						<img src={props.logo} alt={props.title} />
					</div>
				</div>

				<div className="patner__content">
					<p
						dangerouslySetInnerHTML={{
							__html: props.text,
						}}
					/>
				</div>
			</CharityWapper>
		</>
	);
};

export default CharityPartners;
